import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85')
];

export const server_loads = [2,8];

export const dictionary = {
		"/(app)": [9,[2]],
		"/(test)/acc": [78,[8]],
		"/(app)/bo/db": [10,[2,3]],
		"/(app)/bo/logs": [11,[2,3]],
		"/(app)/bo/monitor": [12,[2,3]],
		"/(app)/bo/pdf2data": [13,[2,3]],
		"/(app)/bo/settings": [14,[2,3,4]],
		"/(app)/bo/settings/accounts": [15,[2,3,4]],
		"/(app)/bo/settings/ads_conversion": [16,[2,3,4]],
		"/(app)/bo/settings/firebase": [17,[2,3,4]],
		"/(app)/bo/settings/queries": [18,[2,3,4]],
		"/(app)/bo/subscription": [19,[2,3,5]],
		"/(app)/bo/subscription/features": [~20,[2,3,5]],
		"/(app)/bo/subscription/tables": [~21,[2,3,5]],
		"/(app)/categories": [22,[2]],
		"/(app)/categories/copy/[id=uuid]": [23,[2]],
		"/(app)/categories/edit/[id=uuid]": [24,[2]],
		"/(app)/categories/new": [25,[2]],
		"/(test)/check": [79,[8]],
		"/(app)/check/invoicer-app-health-check": [~26],
		"/(app)/clientShippingLocations/edit/[id=uuid]": [27,[2]],
		"/(app)/clientShippingLocations/new": [28,[2]],
		"/(app)/clients": [29,[2]],
		"/(app)/clients/edit/[id=uuid]": [30,[2]],
		"/(app)/clients/new": [31,[2]],
		"/(app-others)/cookie-errors": [73,[7]],
		"/(app)/estimates": [32,[2]],
		"/(app)/estimates/client/[id=uuid]": [33,[2]],
		"/(app)/estimates/copy/[id=uuid]": [~34,[2]],
		"/(app)/estimates/edit/[id=uuid]": [~35,[2]],
		"/(app)/estimates/item/[id=uuid]": [36,[2]],
		"/(app)/estimates/new": [37,[2]],
		"/(app)/estimates/templates": [38,[2]],
		"/(app)/estimates/view/[id=uuid]": [~39,[2]],
		"/(test)/fonts/arabic": [80,[8]],
		"/(app)/invoicer-ai-health-check": [40],
		"/(app)/invoices": [41,[2]],
		"/(app)/invoices/client/[id=uuid]": [42,[2]],
		"/(app)/invoices/copy/[id=uuid]": [~43,[2]],
		"/(app)/invoices/edit/[id=uuid]": [~44,[2]],
		"/(app)/invoices/item/[id=uuid]": [45,[2]],
		"/(app)/invoices/new": [46,[2]],
		"/(app)/invoices/templates": [47,[2]],
		"/(app)/items": [48,[2]],
		"/(app)/items/copy/[id=uuid]": [49,[2]],
		"/(app)/items/edit/[id=uuid]": [50,[2]],
		"/(app)/items/new": [51,[2]],
		"/(test)/listbuttons": [81,[8]],
		"/(app)/login": [52,[2]],
		"/(app)/login/login_reset_pwd": [53,[2]],
		"/(test)/new": [82,[8]],
		"/(sentry)/sentry-example": [76],
		"/(sentry)/sentry_send/[msg]": [77],
		"/(sentry)/sentry": [74],
		"/(sentry)/sentry/[msg]": [~75],
		"/(app)/settings": [54,[2,6]],
		"/(app)/settings/application": [55,[2,6]],
		"/(app)/settings/billing": [56,[2,6]],
		"/(app)/settings/estimate": [57,[2,6]],
		"/(app)/settings/invoice[...section]": [58,[2,6]],
		"/(app)/settings/notifications": [59,[2,6]],
		"/(app)/signup": [60,[2]],
		"/(app)/stub/[id=uuid]": [~61,[2]],
		"/(app)/styles/buttons": [62,[2]],
		"/(app)/styles/layouthead": [63,[2]],
		"/(app)/styles/selector": [64,[2]],
		"/(app)/styles/statuspill": [65,[2]],
		"/(app)/styles/switchs": [66,[2]],
		"/(app)/subscribe": [67,[2]],
		"/(app)/taxes": [68,[2]],
		"/(app)/taxes/edit/[id]": [69,[2]],
		"/(app)/taxes/new": [70,[2]],
		"/(app)/templates/edit/[id]/[docType]": [71,[2]],
		"/(test)/test-01": [83,[8]],
		"/(test)/test-02": [84,[8]],
		"/(app)/tests/aws": [72,[2]],
		"/(test)/tooltip": [85,[8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';